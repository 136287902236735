import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { useFeature } from '@optimizely/react-sdk'

import Maintenance from 'pages/maintenance'
import OAuthLogin from 'pages/oauth-login'
import OAuthMfaChallenge from 'pages/oauth-mfa-challenge'
import { ResetPasswordPage } from 'pages/reset-password/index'
import { ResetPasswordSuccess } from 'pages/reset-password/resetSuccess'
import { routes } from 'src/routes'

import ForgotPassword from './pages/forgot-password'
import Login from './pages/login'
import MfaChallenge from './pages/mfa-challenge'
import OAuthAuthorization from './pages/oauth-authorization'
import PageAnalytics from './utils/analytics/PageAnalytics'
import SetPageTitle from './utils/SetPageTitle'

const MainRouter = () => {
  return (
    <BrowserRouter>
      <>
        <SetPageTitle />

        {/* Note: Track all authenticated and unauthenticated page views to GA */}
        <PageAnalytics />

        <Switch>
          {/* For pages don't require authentication or require no authentication */}

          <Route path="/forgot-password" component={ForgotPassword} exact />
          <Route path="/mfa-challenge" component={MfaChallenge} exact />
          <Route path="/oauth" component={OAuthLogin} exact />
          <Route
            path="/oauth/mfa-challenge"
            component={OAuthMfaChallenge}
            exact
          />
          <Route
            path={routes.oAuth.authorize}
            component={OAuthAuthorization}
            exact
          />

          <Route path="/maintenance" component={Maintenance} exact />

          <Route
            path={routes.resetPassword.index}
            component={ResetPasswordPage}
            exact
          />

          <Route
            path={routes.resetPassword.success}
            component={ResetPasswordSuccess}
            exact
          />

          <Route path="/*" component={Login} />
        </Switch>
      </>
    </BrowserRouter>
  )
}

// Note: This component binds top-level 'page' components to various URL paths
export default MainRouter
