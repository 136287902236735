import React, { useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { AcornsIcon } from '@acorns/icons'
import { Card, CardContent, Heading } from '@acorns/web-components'
import { useAnalyticsClient } from '@acorns/web-utils'
import { useFeature } from '@optimizely/react-sdk'
import styled from 'styled-components'

import { style } from 'theme'
import { maintenanceScreenWeb } from 'utils/experiments/features'
import { MaintenanceScreenViewed } from 'utils/segment'
import { MAINTENANCE_CARD } from 'utils/testing/testIds'

export default () => {
  const [maintenanceScreenWebIsEnabled, variables, clientReady, didTimeout] =
    useFeature(maintenanceScreenWeb, {
      autoUpdate: true,
    })
  const analytics = useAnalyticsClient()
  const location = useLocation()

  if (!clientReady && !didTimeout) {
    return null
  }

  if (clientReady && !maintenanceScreenWebIsEnabled) {
    return <Redirect to="/" />
  }

  useEffect(() => {
    analytics.track('Screen Viewed', MaintenanceScreenViewed(location.pathname))
  }, [])

  const defaultTitle = 'Under Maintenance'
  const defaultDetails =
    "We are currently performing our routine maintenance on our systems. Don't worry, your account is safe and we will be back shortly!"

  const title = (variables?.title as string) || defaultTitle
  const details = (variables?.details as string) || defaultDetails

  const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${style((theme) => theme.colors.ivory)};
    width: 100%;
    height: 100%;
    padding: 20px;
  `

  const StyledCard = styled(Card)`
    max-width: 500px;
    min-height: 300px;
  `

  const StyledCardContent = styled(CardContent)`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
  `

  const Title = styled(Heading)`
    text-align: center;
    padding: 20px 0;
  `

  const Details = styled.p`
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    color: ${style((theme) => theme.colors.slate)};
    text-align: center;
    margin: 0;
  `

  return (
    <Container>
      <StyledCard data-testid={MAINTENANCE_CARD}>
        <StyledCardContent padding={'large'}>
          <AcornsIcon icon={AcornsIcon.Icon.AcornsLogo} width={38} />
          <Title>{title}</Title>
          <Details>{details}</Details>
        </StyledCardContent>
      </StyledCard>
    </Container>
  )
}
