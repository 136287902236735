var createMockOptimizelyInstance = function createMockOptimizelyInstance(_ref) {
  var _ref$success = _ref.success,
      success = _ref$success === void 0 ? true : _ref$success,
      _ref$isReady = _ref.isReady,
      isReady = _ref$isReady === void 0 ? jest.fn : _ref$isReady,
      _ref$isFeatureEnabled = _ref.isFeatureEnabled,
      isFeatureEnabled = _ref$isFeatureEnabled === void 0 ? true : _ref$isFeatureEnabled,
      _ref$getFeatureVariab = _ref.getFeatureVariables,
      getFeatureVariables = _ref$getFeatureVariab === void 0 ? jest.fn : _ref$getFeatureVariab;
  return {
    onReady: jest.fn(function () {
      return Promise.resolve({
        success: success
      });
    }),
    getIsUsingSdkKey: jest.fn,
    isReady: isReady,
    getIsReadyPromiseFulfilled: jest.fn,
    user: {
      id: 'test user id'
    },
    isFeatureEnabled: isFeatureEnabled,
    getFeatureVariables: getFeatureVariables,
    getFeatureVariableDouble: jest.fn
  };
};

export { createMockOptimizelyInstance };