import { parse } from 'query-string';
import { EARLY_ACCESS_SUBDOMAIN, OPTIMIZELY_OVERRIDE, OPTIMIZELY_ENVIRONMENT_STORAGE_KEY, FEATURE_TOGGLE_ENVIRONMENT_QUERY_KEY, OPTIMIZELY_ENVIRONMENT_MAP } from './constants';
export var isEarlyAccess = function isEarlyAccess() {
  return window.location.origin.includes(EARLY_ACCESS_SUBDOMAIN);
};
export var allowFeatureToggleOverride = function allowFeatureToggleOverride(environment) {
  return environment.get('buildEnv') !== 'production' || isEarlyAccess();
};
export var shimOptimizelyClient = function shimOptimizelyClient(optimizely) {
  var original = optimizely.isFeatureEnabled.bind(optimizely);

  optimizely.isFeatureEnabled = function (feature, overrideUserId, overrideAttributes) {
    var override = localStorage.getItem("".concat(OPTIMIZELY_OVERRIDE).concat(feature));
    if (override === 'true') return true;
    if (override === 'false') return false;
    return original(feature, overrideUserId, overrideAttributes);
  };
};
export var setFeatureOverride = function setFeatureOverride(feature) {
  localStorage.setItem("".concat(OPTIMIZELY_OVERRIDE).concat(feature[0]), feature[1]);
};
export var removeFeatureOverride = function removeFeatureOverride(feature) {
  localStorage.removeItem("".concat(OPTIMIZELY_OVERRIDE).concat(feature));
};
export var initializeOptimizelyOverride = function initializeOptimizelyOverride(optimizely) {
  var queryParams = "".concat(window.location.search);

  if (queryParams.includes('feature_overrides')) {
    var queryString = parse(queryParams);

    if (typeof queryString.feature_overrides === 'string') {
      var features = queryString.feature_overrides.split(',').map(function (featureToggle) {
        return featureToggle.split('.');
      });
      features.forEach(function (feature) {
        return setFeatureOverride(feature);
      });
    }
  }

  shimOptimizelyClient(optimizely);
};
export var setOptimizelyEnvironmentSessionStorage = function setOptimizelyEnvironmentSessionStorage(optimizelyQueryEnvironment) {
  var optimizelyQueryEnvironmentIndex = OPTIMIZELY_ENVIRONMENT_MAP[optimizelyQueryEnvironment];
  sessionStorage.setItem(OPTIMIZELY_ENVIRONMENT_STORAGE_KEY, optimizelyQueryEnvironmentIndex.toString());
}; // allow environment override from query params

export var getInitialOptimizelyEnvironment = function getInitialOptimizelyEnvironment(environment) {
  var queryParams = window.location.search;
  var optimizelyEnvironmentIndex = parseInt(environment.get('optimizelyEnvIndex'), 10); // we don't want this override feature in production

  if (allowFeatureToggleOverride(environment)) {
    if (queryParams.includes(FEATURE_TOGGLE_ENVIRONMENT_QUERY_KEY)) {
      var queryString = parse(queryParams);
      var optimizelyQueryEnvironment = queryString[FEATURE_TOGGLE_ENVIRONMENT_QUERY_KEY];

      if (typeof optimizelyQueryEnvironment === 'string') {
        var _optimizelyQueryEnvir;

        optimizelyQueryEnvironment = (_optimizelyQueryEnvir = optimizelyQueryEnvironment) === null || _optimizelyQueryEnvir === void 0 ? void 0 : _optimizelyQueryEnvir.toLowerCase(); // allows for persistence, ie: refreshing page

        setOptimizelyEnvironmentSessionStorage(optimizelyQueryEnvironment);
      }
    }
  }

  var storageOptimizelyEnvironment = sessionStorage.getItem(OPTIMIZELY_ENVIRONMENT_STORAGE_KEY);

  if (storageOptimizelyEnvironment) {
    optimizelyEnvironmentIndex = parseInt(storageOptimizelyEnvironment, 10);
  }

  return optimizelyEnvironmentIndex;
};