import { EventDispatcher, eventDispatcher } from '@optimizely/optimizely-sdk'

const eventProxy: EventDispatcher = {
  dispatchEvent(event, callback): void {
    event.url = `${process.env.FRONTEND_PROXY_HOST}/v1/events`
    eventDispatcher.dispatchEvent(event, callback)
  },
}

export default eventProxy
