import React, { Component } from 'react'
import { ApolloProvider } from 'react-apollo'

import { ModalActionTypes } from '@acorns/web-components'
import {
  AuthStrategy,
  ClientAuthMethod,
  ClientPlatform,
  HttpHeader,
  Type,
  inferAuthHeaders,
  read,
} from '@acorns/web-utils'
import { ReactSDKClient } from '@optimizely/react-sdk'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, Observable, from } from 'apollo-link'
import { BatchHttpLink } from 'apollo-link-batch-http'
import DebounceLink from 'apollo-link-debounce'
import { onError } from 'apollo-link-error'
import { path } from 'ramda'
import { compose } from 'recompose'
import UAParser from 'ua-parser-js'

import Environment, { withEnv } from 'utils/environment'
import { ErrorDetails, ErrorPayload, withApolloErrorModal } from 'utils/error'

type GetCustomHandlerType = (
  name: string,
) => (error: ErrorPayload) => ErrorDetails | boolean

export type ApolloProviderWrapperProps = {
  getCustomHandler: GetCustomHandlerType
  graphqlUrl: string
  env: Environment
  optimizely: ReactSDKClient
  showErrorModal: (details: ErrorDetails) => void
  closeErrorModal: () => void
}

const DEBOUNCE_TIMEOUT = 250

export const hasServiceUnavailableError = (errors: any[] = []) =>
  Boolean(errors.find(({ code }) => code === 'service_unavailable'))

// Wrapping ApolloProvider so that apollo-link-error can access React props
export class ApolloProviderWrapper extends Component<
  ApolloProviderWrapperProps,
  {}
> {
  public render() {
    return (
      <ApolloProvider client={this.createClient()}>
        {this.props.children}
      </ApolloProvider>
    )
  }

  protected createClient() {
    // Note: Attaches some HTTP header on every network request
    const headersMiddleware = this.createHeadersMiddleware()

    // Note: All GraphQL and network errors get run through this
    // so we can take appropriate action
    const errorLink = this.createErrorLink()

    // Note: Performs debounce logic on queries that have a `debounceKey` configured
    const debounceLink = new DebounceLink(DEBOUNCE_TIMEOUT) as any

    // Note: Batch queries executed within an interval together
    // in a single network request
    const batchHttpLink = new BatchHttpLink({
      uri: this.props.graphqlUrl,
      credentials: 'include',
    })

    const links = [headersMiddleware, errorLink, debounceLink, batchHttpLink]

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: from(links),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-first',
        },
      },
    })
  }

  protected createHeadersMiddleware() {
    return new ApolloLink((operation, forward) => {
      return new Observable((observer) => {
        const userAgentParser = new UAParser()
        const { browser, device, os } = userAgentParser.getResult()

        const customHeaders = {
          [HttpHeader.ClientAppName]: 'web-app',
          [HttpHeader.ClientBrowserVersion]: browser.version,
          [HttpHeader.ClientBrowserName]: browser.name,
          [HttpHeader.ClientBuild]: process.env.VERSION,
          [HttpHeader.ClientHardware]: device.model,
          [HttpHeader.ClientOperatingSystem]: os.name,
          [HttpHeader.ClientPlatform]: ClientPlatform.Web,
        }

        customHeaders[HttpHeader.AuthStrategy] = AuthStrategy.Jwt

        if (
          process.env.BUILD_ENV === 'dev' ||
          process.env.BUILD_ENV === 'staging' ||
          process.env.BUILD_ENV === 'production'
        ) {
          // This HTTP header needs to be set for deployed environments for implicit cookie auth.
          // For local development, make sure BUILD_ENV is not set to one of the above values.
          customHeaders[HttpHeader.ClientAuthMethod] = ClientAuthMethod.Cookies
        }

        if (read(Type.csrfToken)) {
          // This is only relevant when we are calling the `logout` mutation to close an active session.
          const authHeaders = inferAuthHeaders()
          Object.assign(customHeaders, authHeaders)
        }

        operation.setContext({
          headers: customHeaders,
        })

        return forward(operation).subscribe(observer)
      })
    })
  }

  protected errorLinkHandler({ graphQLErrors, networkError, operation }) {
    if (hasServiceUnavailableError(graphQLErrors)) {
      this.handleServiceUnavailableError()
    } else {
      this.handleError(graphQLErrors, networkError, operation)
    }
  }

  protected createErrorLink() {
    return onError(this.errorLinkHandler.bind(this))
  }

  protected handleAuthenticationError() {
    const errorModalHandle = () => {
      document.querySelector('[data-id]')
        ? this.props.closeErrorModal()
        : location.reload()
    }

    this.props.showErrorModal({
      title: 'Logged Out',
      body: "You've been logged out due to inactivity. Please log in to proceed.",
      actions: [
        {
          handler: errorModalHandle,
          label: 'Ok',
          type: ModalActionTypes.primary,
        },
      ],
    })
  }

  protected handleServiceUnavailableError() {
    this.props.showErrorModal({
      title: 'Please Contact Support',
      body: 'We are unable to process your request online, but our support team is here to help. Please contact us and we will work to resolve this as soon as possible.',
      actions: [
        {
          handler: this.props.closeErrorModal,
          label: 'Cancel',
          type: ModalActionTypes.secondary,
        },
        {
          href: 'https://help.acorns.com/contact',
          label: 'Contact Support',
          type: ModalActionTypes.primary,
        },
      ],
    })
  }

  protected handleError(graphQLErrors, networkError, operation) {
    const opertionType = path(
      ['query', 'definitions', '0', 'operation'],
      operation,
    )

    const handler = this.props.getCustomHandler(operation.operationName)

    let errorDetails

    if (typeof handler === 'function') {
      errorDetails = handler({
        graphQLErrors,
        networkError,
      })
    }

    return Promise.resolve(errorDetails).then((results) => {
      // Disable error modal if handler returns false
      if (typeof results === 'boolean' && !results) {
        return
      }

      if (opertionType === 'mutation' || results) {
        this.props.showErrorModal(results)
      }
    })
  }
}

const enhance = compose(withApolloErrorModal, withEnv)

export const Provider = enhance(ApolloProviderWrapper)
