import React from 'react'

import { OptimizelyProvider, ReactSDKClient } from '@optimizely/react-sdk'

type Props = {
  optimizely: ReactSDKClient
  children: React.ReactNode
}

const getAppVersion = () =>
  process.env.RELEASE_TAG
    ? process.env.RELEASE_TAG
    : (process.env.HEAD?.substring(0, 7) as string)

const Provider = (props: Props) => {
  const user = {
    // For now, login-app only uses Optimizely for toggling "Under Maintenance"
    // and likely doesn't benefit from a unique user id passed into Optimizely.
    // There is also no uuid to associate since the user hasn't authenticated yet.
    id: 'login-app-user',
    attributes: {
      version: `login-app-${getAppVersion()}`,
    },
  }

  return (
    <OptimizelyProvider
      optimizely={props.optimizely}
      user={user}
      timeout={2000}
    >
      {props.children}
    </OptimizelyProvider>
  )
}

export default Provider
