import React, { FormEvent } from 'react'
import { graphql } from 'react-apollo'
import { Link, Redirect, withRouter } from 'react-router-dom'

import { AcornsIcon } from '@acorns/icons'
import {
  Button,
  ConfirmationModal,
  ModalActionTypes,
  PrivateInputV3,
  Spinner,
  TextInputV3,
  Title,
} from '@acorns/web-components'
import { GenericAnalyticsClient, withAnalyticsClient } from '@acorns/web-utils'
import { useFeature } from '@optimizely/react-sdk'
import { withFormik } from 'formik'
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withState,
} from 'recompose'
import styled from 'styled-components'

import AlertBadge from 'assets/icons/alert-badge.png'
import acornsTitle from 'assets/images/acorns-title.svg'
import Layout from 'components/Layout'
import OAuthPartnerContent, {
  KNOWN_OAUTH_PARTNERS,
} from 'components/OAuthPartnerContent'
import theme from 'theme'
import { withEnv } from 'utils/environment'
import {
  maintenanceScreenWeb,
  trustThisDeviceWeb,
} from 'utils/experiments/features'
import { logInViewed } from 'utils/segment'
import { OAuthPartnerConfig } from 'utils/shared-types'
import { FormContainer, InputContainer } from 'utils/styled-components'
import { LOGIN_FORM, SIGN_UP_INSTEAD_LINK } from 'utils/testing/testIds'
import withAnalyticsIdentity from 'utils/with-analytics-identity'

import { registrationUrlRedirect } from '../../utils/login-utils'
import { withAuthenticateErrorHandler } from './errors'
import AuthenticateExceptionHandler from './exceptions'
import {
  Values,
  handleFormSubmit,
  mapPropsToValues,
  validationSchema,
} from './form'
import Authenticate from './queries/authenticate.gql'
import Texts from './texts'

type CustomTextValues = {
  login: string
  signupDescription?: string
  signUpCta: string
}

type OAuthPartnerParams = {
  oAuthPartnerParams?: OAuthPartnerConfig
  client_id?: string
}

export type Props = {
  children: any
  copy: string
  customTexts: CustomTextValues
  values: Values
  isSubmitting: boolean
  isValid: boolean
  openRegistration: () => void
  isModalOpen: boolean
  isValidationModalOpen: boolean
  closeModal: () => void
  handleChange: (event: FormEvent<HTMLInputElement>) => void
  closeErrorModal: () => void
  handleSubmit: () => void
  handleBlur: (event: FormEvent<HTMLInputElement>) => void
  giftcardPage: boolean
  env: any
  exception: string
  analytics: GenericAnalyticsClient
  timeOnLogInView: number
  setTimeOnLogInView: (time: number) => void
  trustThisDeviceWebIsEnabled: boolean
  isOAuth?: boolean
  oAuthPartnerParams?: OAuthPartnerParams
  setOAuthPartnerParams: (params: OAuthPartnerParams) => void
  chromeExtensionPartnerFlow?: boolean
  setChromeExtensionPartnerFlow?: (value: boolean) => void
}

const GiftcardContainer = styled.div`
  display: flex;
  max-width: 340px;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.ivory};
  border-radius: 10px;
  margin: auto;
  padding: 20px;
  margin-bottom: 30px;
`

const GiftcardIconContainer = styled.div`
  margin-right: 12px;
`
const GiftcardContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
`

const SignupLink = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;
  color: ${theme.colors.green};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const SignupCopy = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${theme.colors.slate};
  margin-bottom: 5px;
  margin-top: -2px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const StyledDisclosureRight = styled(AcornsIcon)`
  margin-left: 8px;

  path {
    &[fill] {
      fill: ${(props) => props.theme.colors.green};
    }
  }
`

const withRouteSpecificText = withProps(() => {
  const { pathname } = window.location
  const customTexts = Texts[pathname] || Texts.default
  return { customTexts }
})

const GiftcardSignupSection = ({ customTexts, openRegistration }) => (
  <GiftcardContainer>
    <GiftcardIconContainer>
      <AcornsIcon icon={AcornsIcon.Icon.UtilityGift} height={20} width={20} />
    </GiftcardIconContainer>
    <GiftcardContentContainer>
      <SignupCopy>{customTexts.signupDescription}</SignupCopy>
      <SignupLink
        data-testid={SIGN_UP_INSTEAD_LINK}
        to="/redeem"
        onClick={openRegistration}
      >
        {customTexts.signUpCta}
        <StyledDisclosureRight
          height={10}
          width={10}
          icon={AcornsIcon.Icon.DisclosureRight}
        />
      </SignupLink>
    </GiftcardContentContainer>
  </GiftcardContainer>
)

export const enhance = compose<any, any>(
  withAnalyticsClient,
  withAnalyticsIdentity,
  withEnv,
  withRouter,
  graphql(Authenticate, { name: 'login' }),
  withState('isModalOpen', 'setIsModalOpen', false),
  withState('exception', 'setException', ''),
  withState('isValidationModalOpen', 'setIsValidationModalOpen', false),
  withState('timeOnLogInView', 'setTimeOnLogInView', null),
  withState('oAuthPartnerParams', 'setOAuthPartnerParams', {}),
  withState(
    'chromeExtensionPartnerFlow',
    'setChromeExtensionPartnerFlow',
    false,
  ),

  withProps(() => {
    const [trustThisDeviceWebIsEnabled] = useFeature(trustThisDeviceWeb)
    return { trustThisDeviceWebIsEnabled }
  }),
  withFormik({
    validationSchema,
    mapPropsToValues,
    handleSubmit: handleFormSubmit,
  }),
  withRouteSpecificText,
  withProps(() => ({
    giftcardPage: window.location.pathname === '/redeem',
  })),
  withHandlers({
    openRegistration:
      ({ env }) =>
      () => {
        const registrationUrl = env.get('registrationUrl')
        const windowLocation = window.location
        const RegistrationUrlArgs = {
          registrationUrl,
          windowLocation,
        }

        let registrationUrlWithParams =
          registrationUrlRedirect(RegistrationUrlArgs)

        // acorns-hello uses this query string parameter to identify a new user to clear an existing session if one exists.
        registrationUrlWithParams += registrationUrlWithParams.includes('?')
          ? '&newUser=true'
          : '?newUser=true'

        window.location.href = registrationUrlWithParams
      },
    closeModal:
      ({ setIsModalOpen, setIsValidationModalOpen }) =>
      () => {
        setIsModalOpen(false)
        setIsValidationModalOpen(false)
      },
  }),
  lifecycle<Props, {}>({
    componentDidMount() {
      this.props.analytics.track('Screen Viewed', logInViewed)
      this.props.setTimeOnLogInView(Date.now())

      const searchParams: OAuthPartnerParams = {}
      const queryParams = new URLSearchParams(
        window.location.search.substring(1),
      )
      queryParams.forEach((value, key) => {
        searchParams[key] = value
      })

      if (
        searchParams.hasOwnProperty('app_client_id') &&
        !searchParams.hasOwnProperty('app_client_name')
      ) {
        throw new Error('app_client_name is missing')
      }

      if (searchParams.hasOwnProperty('client_id')) {
        this.props.setChromeExtensionPartnerFlow(true)
      }

      this.props.setOAuthPartnerParams(searchParams)
    },
  }),
  withAuthenticateErrorHandler,
)

const LoginTitle = styled(Title)`
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
`

const Email = styled(TextInputV3)`
  display: block;
  max-width: 340px;
  margin-bottom: 20px;
  width: 100%;
`

const Password = styled(PrivateInputV3)`
  display: block;
  max-width: 340px;
  margin-bottom: 30px;
  width: 100%;
`

const ForgotPass = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  color: ${theme.colors.stone};
  line-height: 22px;
  letter-spacing: 0px;
  text-decoration: none;
  text-align: center;
`

const LoginButton = styled(Button)`
  margin: auto;
  max-width: 340px;
  width: 100%;
  margin-bottom: 20px;
`

const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`

export const Login = ({
  closeModal,
  customTexts,
  giftcardPage,
  env,
  handleChange,
  handleSubmit,
  isModalOpen,
  isSubmitting,
  isValid,
  isValidationModalOpen,
  openRegistration,
  values,
  exception,
  isOAuth,
  oAuthPartnerParams,
  chromeExtensionPartnerFlow,
}: Props) => {
  const [maintenanceScreenWebIsEnabled, _, clientReady, didTimeout] =
    useFeature(maintenanceScreenWeb, {
      autoUpdate: true,
    })

  if (!clientReady && !didTimeout) {
    return null
  }

  if (maintenanceScreenWebIsEnabled) {
    return <Redirect to="/maintenance" />
  }

  const showOAuthPartnerContent = (
    isOauth: boolean,
    params: OAuthPartnerParams,
  ) => {
    if (isOauth) {
      const isPartner =
        params.page_type && KNOWN_OAUTH_PARTNERS.includes(params.page_type)
      const isChromeExtensionPartner =
        params.client_id && KNOWN_OAUTH_PARTNERS.includes(params.client_id)
      return isPartner || isChromeExtensionPartner
    }
    return false
  }

  return (
    <Layout
      title={<img src={acornsTitle} width={97} height={26} />}
      showBack={false}
      rightButton={
        isOAuth
          ? null
          : [
              {
                text: 'Sign Up',
                actionText: 'Sign Up',
                handler: openRegistration,
              },
            ]
      }
    >
      <FormContainer>
        <Form
          noValidate={true}
          data-id="login-form"
          data-testid={LOGIN_FORM}
          giftcardPage={giftcardPage}
          onSubmit={handleSubmit}
          name="login-form"
        >
          {showOAuthPartnerContent(isOAuth, oAuthPartnerParams) ? (
            <OAuthPartnerContent
              oAuthPartnerParams={oAuthPartnerParams}
              chromeExtensionPartnerFlow={chromeExtensionPartnerFlow}
            />
          ) : (
            <LoginTitle>Log in to Acorns</LoginTitle>
          )}
          <InputContainer>
            <Email
              label={{ value: 'Email' }}
              inputProps={{
                autoFocus: true,
                name: 'email',
                placeholder: 'Enter your email',
                onChange: handleChange,
                value: values.email,
                type: 'email',
              }}
            />
          </InputContainer>

          <InputContainer>
            <Password
              label={{ value: 'Password' }}
              inputProps={{
                name: 'password',
                placeholder: 'Enter your password',
                value: values.password,
                onChange: handleChange,
                type: 'password',
              }}
            />
          </InputContainer>

          {giftcardPage && (
            <GiftcardSignupSection
              customTexts={customTexts}
              openRegistration={openRegistration}
            />
          )}
          <ActionsContainer>
            <LoginButton
              disabled={isSubmitting || !isValid}
              onPress={handleSubmit}
              buttonType="submit"
              name="login-button"
            >
              {!isSubmitting ? (
                customTexts.login
              ) : (
                <Spinner height={20} width={20} color={'white'} />
              )}
            </LoginButton>
            <ForgotPass to="/forgot-password">Forgot Password?</ForgotPass>
          </ActionsContainer>
        </Form>
      </FormContainer>

      <ConfirmationModal
        isOpen={isValidationModalOpen}
        actions={[
          {
            label: 'OK',
            type: ModalActionTypes.primary,
            handler: closeModal,
          },
        ]}
        title="Invalid Email Address"
        message="Sorry, it doesn't look like the email address is valid. Please try again."
        image={AlertBadge}
      />
      <AuthenticateExceptionHandler
        exception={exception}
        handleCloseExceptionModal={closeModal}
        isExceptionModalOpen={isModalOpen}
        env={env}
      />
    </Layout>
  )
}

export default enhance(Login)
