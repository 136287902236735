import debug from 'debug'

import Environment from '../utils/environment'

// Note: `process.env.MY_VARIABLE` get replaced at compile-time
const CONFIGURATION = {
  graphql: process.env.GRAPHQL_URL,
  registrationUrl: process.env.REGISTRATION_URL,

  sentry: process.env.SENTRY_URL,

  googleAnalytics: process.env.GOOGLE_ANALYTICS_ID,

  rudderStackWriteKey: process.env.RUDDER_STACK_WRITE_KEY,
  rudderStackDataPlaneUrl: process.env.RUDDER_STACK_DATA_PLANE_URL,

  analyticsClientType: process.env.ANALYTICS_CLIENT_TYPE,

  optimizelySdkKey: process.env.OPTIMIZELY_SDK_KEY,
  frontendProxyHost: process.env.FRONTEND_PROXY_HOST,

  defaultRedirect: process.env.DEFAULT_REDIRECT,
  chromeExtensionPartnerLoginOAuthRedirectUrl:
    process.env.CHROME_EXTENSION_PARTNER_LOGIN_OAUTH_REDIRECT_URL,
  partnerLoginOAuthRedirectUrl: process.env.PARTNER_LOGIN_OAUTH_REDIRECT_URL,
  suspendedUserRedirectUrl: process.env.SUSPENDED_USER_REDIRECT_URL,

  head: process.env.HEAD || '',
  release: process.env.RELEASE_TAG || '',
  buildEnv: process.env.BUILD_ENV,
}

// Note: Export an environment creator function
export default () => {
  const mode = process.env.BUILD_ENV
  const logger = debug('environment')

  logger(`Running application with "${mode}" environment:`, CONFIGURATION)

  return new Environment(CONFIGURATION, mode)
}
