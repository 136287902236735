import { ReactSDKClient } from '@optimizely/react-sdk'
import { anyString, mockDeep } from 'jest-mock-extended'

export default ({ basicSetup = false } = {}) => {
  const mockReactSDKClient = mockDeep<ReactSDKClient>()

  if (basicSetup) {
    mockReactSDKClient.isReady.calledWith().mockReturnValue(true)
    mockReactSDKClient.isFeatureEnabled
      .calledWith(anyString())
      .mockReturnValue(false)
    mockReactSDKClient.getFeatureVariables
      .calledWith(anyString())
      .mockReturnValue({})
  }

  return mockReactSDKClient
}
