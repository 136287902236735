/* tslint:disable */

import from 'promise-polyfill/src/polyfill'
import from 'regenerator-runtime/runtime'

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'

import { createInstance as createOptimizelyInstance } from '@optimizely/react-sdk'

import { eventProxy } from 'utils/experiments'

import Application from './application'
import createEnvironment from './config/env'
import sentry from './config/sentry'
import theme from './theme'

const APP_ID = 'web-app'
const ENVIRONMENT = createEnvironment()
const GRAPHQL_ENDPOINT = ENVIRONMENT.get('graphql')
const BUILD_ENV = ENVIRONMENT.get('buildEnv')
const HEAD = ENVIRONMENT.get('head')
const SENTRY = ENVIRONMENT.get('sentry')
const RELEASE = ENVIRONMENT.get('release')

// Configure raven-js for sentry exception capturing
sentry(SENTRY, BUILD_ENV, HEAD)

ReactGA.initialize(ENVIRONMENT.get('googleAnalytics'))
ReactGA.set({
  build: HEAD,
  release: RELEASE,
})

const optimizely = createOptimizelyInstance({
  sdkKey: ENVIRONMENT.get('optimizelySdkKey'),
  datafileOptions: {
    urlTemplate: `${ENVIRONMENT.get('frontendProxyHost')}/opt?s=%s`,
  },
  eventDispatcher: eventProxy,
})

export default ReactDOM.render(
  <Application
    appId={APP_ID}
    env={ENVIRONMENT}
    graphqlUrl={GRAPHQL_ENDPOINT}
    optimizely={optimizely}
    theme={theme}
  />,
  document.getElementById('app'),
)
